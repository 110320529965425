<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  summary: {
    type: String,
    required: true,
  },
  invert: {
    type: Boolean,
    default: false,
  },
  centerTitle: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
});
</script>

<template>
  <div :class="['section-header', { 'invert': props.invert }, { 'center-title': props.centerTitle }, { 'large': props.size === 'large' }]">
    <small>{{ props.subtitle }}</small>
    <h1>
      {{ props.title }}
    </h1>
    <div class="summary">
      <p>{{ props.summary }}</p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
.section-header {
  max-width: 20rem;
  @mixin screen xs {
    max-width: 40rem;
  }
}

.center-title {
  text-align: left;
  margin: 0 1rem;
  @mixin screen md {
    text-align: center;
    margin: 0 auto;
    p {
      margin: 0 auto 2.5rem;
    }
  }
}

small {
  @mixin typeface-regular;
  color: var(--color-rose-500);
  margin-bottom: 0.5rem;
  display: block;
}
h1 {
  @mixin typeface-h1;
  color: var(--color-black);
  margin-top: 1rem;
  margin-bottom: 2rem;
}
p {
  @mixin typeface-summary;
  color: var(--color-slate-400);
  max-width: 30rem;
}

.invert {
  h1 {
    color: var(--color-white);
  }
  p {
    color: var(--color-slate-200);
  }
}

.large {
  h1 {
    @mixin typeface-title;
  }
}
</style>
